
import "./stylesheets/Footer.css"


function Footer() {
    return(<div>
        <div id="footer-section">
            <a href="https://github.com/lisamartelly/lisa-personal-website">Made</a> by Lisa Martelly
        </div>
    </div>)
}

export default Footer;