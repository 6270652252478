
import { StrictMode } from 'react';
import Homepage from './Homepage';
import Footer from './Footer';

import "./stylesheets/App.css";


const App = () => {
  return (
    <StrictMode>
        < Homepage />
        < Footer />
    </StrictMode>
  );
};

export default App;
